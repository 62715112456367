<template>
  <div :class="className">
    <div class="container">
      <div
        class="row justify-content-between grid-item"
        :style="getBackground"
        :class="{ disabled: isActive }"
      >
        <div class="col-md-12 d-flex">
          <Spinner v-if="isLoading" />
          <span v-if="!isLoading && item.image_src === ``" class="no-design">
            No Design
          </span>
        </div>
        <button
          class="btn btn-primary btn-sm btn-block blue-btn align-self-end mb-3"
          @click="triggerSubmitButton"
          v-if="!isActive"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "./Spinner.vue";

export default {
  components: { Spinner },
  data() {
    return {};
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
    type: {
      type: String,
      default: "cover",
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    currentValue: {
      type: String,
      default: "_",
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([
      "getPages",
      "getGlobalSettings",
      "getPageDesigns",
      "getLoading",
    ]),
    className: function () {
      const cols = this.cols in [1, 2, 3, 4, 6] ? this.cols : 6;
      const cols_width = 12 / cols;
      return `col-lg-${cols_width} col-md-3 col-sm-4 mb-3`;
    },
    isActive() {
      if (this.currentValue === this.item.id) {
        return true;
      }
      if (this.isLoading) {
        return true;
      }
      return false;
    },
    getBackground() {
      if (this.item.image_src) {
        return `background-image:url(/wp-content/themes/${this.getTemplateFolder}${this.item.image_src})`;
      } else {
        return ``;
      }
    },
    buttonText() {
      let text = "Choose This Design";
      if (this.item.image_src === ``) {
        text = "Choose No Design";
      }
      if (this.item.download_link) {
        text = "Preview";
      }
      return text;
    },
    isLoading() {
      if (this.getLoading) {
        return true;
      }
      if (this.loading) {
        return true;
      }
      return false;
    },
    getTemplateFolder() {
      return this.$root.template_name;
    },
  },
  methods: {
    triggerSubmitButton() {
      this.$emit("triggerPreview", this.item);
    },
  },
};
</script>
<style scoped>
.grid-item {
  font-size: 14px;
  text-align: center;
  border: 1px solid #0f8300;
  height: 252px;
  padding: 20px;
  box-shadow: 0 0 5px #000;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.grid-item > div {
  opacity: 1;
}
.grid-item.active > div {
  opacity: 1;
}
.grid-item.disabled {
  opacity: 0.5;
}
input#activator {
  position: relative;
  right: 0;
}
.no-design {
  text-transform: uppercase;
  display: block;
  margin: auto auto;
  font-weight: 600;
}
</style>