<template>
  <div class="container">
    <div class="row">
      <PageDesignsGridItem
        :cols="cols"
        :key="index"
        v-for="(item, index) in getCoverPages"
        :item="item"
        type="cover"
        @triggerPreview="triggerPreview"
        @triggerSubmit="triggerSubmit"
        :currentValue="currentValue"
        :loading="loading"
      />
    </div>
  </div>
</template>
<script>
import PageDesignsGridItem from "./PageDesignsGridItem";
import { mapGetters } from "vuex";
import PreviewImage from "./PreviewImage.vue";

export default {
  components: {
    PageDesignsGridItem,
  },
  data() {
    return {
      loading: true,
    };
  },
  props: {
    cols: {
      type: Number,
      default: 6,
    },
  },
  computed: {
    ...mapGetters(["getPageDesigns", "getCoverDesignData"]),
    getCoverPages() {
      return this.getPageDesigns.filter((d) =>
        d.groups.find((g) => g === "cover")
      );
    },
    currentValue: {
      get() {
        const [, design] = this.getCoverDesignData;
        return design;
      },
      set(value) {
        this.$store.commit("SET_COVER_DESIGN_DATA", {
          id: this.cover_page_id,
          design: value,
        });
      },
    },
    cover_page_id() {
      return this.getCoverDesignData[0];
    },
  },
  mounted() {
    this.$store.dispatch("getCoverDesign", (data) => {
      this.$store.commit("SET_COVER_DESIGN_DATA", {
        id: data.page_id,
        design: data.design,
      });
      this.loading = false;
    });
  },
  methods: {
    triggerPreview(props) {
      if (!props.download_link) {
        this.triggerSubmit(props);
      } else {
        this.$modal.show(
          PreviewImage,
          {
            item: props,
            id: this.cover_page_id,
            type: "cover",
          },
          {
            name: "modal-" + props.id,
          }
        );
      }
    },

    triggerSubmit(props) {
      this.currentValue = props.id;
      console.log(this.getCoverDesignData);
      this.$store.dispatch("updatePageMeta", [
        this.cover_page_id,
        "page_design",
        props.id,
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
            type: "success",
          });
        },
      ]);
    },
  },
};
</script>

<style scoped>
.draggable-grid {
  background: transparent !important;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>