<template>
  <div class="container">
    <CloseModalButton @close="closeModal" />
    <div class="row">
      <div class="col-md-12 pt-3">
        <div class="card">
          <div class="btn-group" role="group">
            <button
              @click="triggerUpdate"
              type="button"
              class="btn btn-primary"
            >
              Choose This Design
            </button>
            <a
              target="_blank"
              rel="noreferrer noopener"
              :href="item.download_link"
              class="btn btn-success"
              >Download Design</a
            >
          </div>
          <img :src="item.download_link" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CloseModalButton from "./CloseModalButton";
export default {
  components: { CloseModalButton },
  props: {
    item: {
      type: Object,
      default() {
        return {
          id: 0,
        };
      },
    },
    id: {
      type: String,
      default: "0",
    },
    type: {
      type: String,
      default: "cover",
    },
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    triggerUpdate() {
      this.$emit("close");
      if (this.type === "cover") {
        this.$store.dispatch("updatePageMeta", [
          this.id,
          "page_design",
          this.item.id,
          ({ message }) => {
            this.$toast.open({
              message,
              position: "bottom-left",
              type: "success",
            });

            this.$store.commit("SET_COVER_DESIGN_DATA", {
              id: this.id,
              design: this.item.id,
            });
          },
        ]);
      } else if (this.type === "internal") {
        this.$store.dispatch("updateUserMeta", [
          "legacy_journal_default_page",
          this.item.id,
          ({ message }) => {
            this.$toast.open({
              message,
              position: "bottom-left",
              type: "success",
            });
            this.$store.commit("SET_INTERNAL_DESIGN_DATA", this.item.id);
          },
        ]);
      }
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style scoped>
.card {
  width: 100%;
  height: 570px;
  display: block;
  border: none;
  overflow-x: hidden;
  overflow-y: scroll;
}
.btn-group {
  position: absolute;
  top: 0;
}
</style>